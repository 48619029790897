import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import { v4 } from 'uuid';

import Vue from 'vue';

// Application
import GetInternetStatusService
  from '@/modules/internet-status/application/services/get-internet-status-service';
import GetOnBoardingIdentityVerificationStatusQuery
  from '@/modules/on-boarding/identity-verification/application/queries/get-on-boarding-identity-verification-status-query';
import SearchOnBoardingStepsQuery
  from '@/modules/on-boarding/steps/application/queries/search-on-boarding-steps-query';
import GetMatiVerificationQuery
  from '@/modules/on-boarding/identity-verification/application/queries/get-mati-verification-query';
import CreateOnBoardingStepCommand
  from '@/modules/on-boarding/steps/application/commands/create-on-boarding-step-command';
import SignOutService
  from '@/modules/authentication/application/services/sign-out-service';

// Domain
import {
  MatiVerificationEntity,
} from '@/modules/on-boarding/identity-verification/domain/entities/mati-verification-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import { EventBus } from '@/modules/shared/domain/bus/event-bus';

export default class VerifyingViewModel {
  @Inject(TYPES.SEARCH_ON_BOARDING_STEPS_QUERY)
  readonly searchOnBoardingStepsQuery!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.GET_INTERNET_STATUS_SERVICE)
  readonly getInternetStatusService!: GetInternetStatusService;

  @Inject(TYPES.GET_ON_BOARDING_IDENTITY_VERIFICATION_STATUS_QUERY)
  // eslint-disable-next-line max-len
  readonly getOnBoardingIdentityVerificationStatusQuery!: GetOnBoardingIdentityVerificationStatusQuery;

  @Inject(TYPES.GET_ON_BOARDING_MATI_VERIFICATION_QUERY)
  private readonly get_mati_verification_query!: GetMatiVerificationQuery;

  @Inject(TYPES.CREATE_ON_BOARDING_STEP_COMMAND)
  readonly createOnBoardingStepCommand!: CreateOnBoardingStepCommand;

  @Inject(TYPES.EVENT_BUS)
  private readonly event_bus!: EventBus;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  private readonly view: Vue;

  readonly i18n_namespace = 'sign-up.verify-identity.verifying';

  verification_status = '';

  loading = false;

  mati_verification: MatiVerificationEntity = {
    id: '',
    customer_id: '',
    status: '',
    attempt_counter: 0,
    created_at: '',
    updated_at: '',
  };

  confirm_identity_data_id = v4();

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (identifier: string) => (
    i18n.t(`${this.i18n_namespace}.${identifier}`) as string
  )

  get internetStatus() {
    return this.getInternetStatusService.execute();
  }

  get identityVerificationStatus() {
    return this.getOnBoardingIdentityVerificationStatusQuery.execute();
  }

  get identityVerificationSucceed() {
    const last_step = this.identityVerificationStatus;
    const current_flow_id = this.getCurrentMatiFlow();

    if (current_flow_id === last_step.mati_flow_id || !last_step.mati_flow_id) {
      if ('success' in last_step && !last_step.success) {
        this.unexpectedError();
      }

      if (last_step.name === 'verification_expired') {
        this.verificationExpired();
      }

      if (last_step.percent === 100) {
        this.verification_status = last_step.status || '';
        if (this.verification_status === 'has_error') {
          this.hasError();
        }

        if (this.verification_status === 'manual_review_required') {
          this.manualReviewRequired();
        }
      }
    }

    return (
      (
        this.identityVerificationStatus.status === 'verified'
        && this.identityVerificationStatus.percent === 100
      )
      || this.mati_verification.status === 'verified'
      || this.mati_verification.status === 'manually_verified'
    );
  }

  loadMatiVerificationStatus = async () => {
    try {
      this.mati_verification = await this.get_mati_verification_query.execute(true);
      if (this.mati_verification.status === 'verified' || this.mati_verification.status === 'manually_verified') {
        this.identityVerificationStatus.percent = 100;
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.cant_get_mati_verification'));
    }
  }

  get canContinue() {
    return (
      this.identityVerificationSucceed
        && this.internetStatus
    );
  }

  getCurrentMatiFlow = () => sessionStorage.getItem('mati_flow_id');

  nextStep = async () => {
    this.loading = true;

    try {
      await this.createOnBoardingStepCommand.execute({
        id: this.confirm_identity_data_id,
        current_step: 'confirm_identity_data',
        payload: {
          status: 'pending',
          person: {},
          customer_agreements: [],
        },
      });
      this.view.$emit('nextStep');
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.cant_update'));
    } finally {
      this.loading = false;
    }
  }

  unexpectedError = () => {
    this.view.$emit('unexpectedError');
  }

  hasError = () => {
    this.view.$emit('hasError');
  }

  verificationExpired = () => {
    this.view.$emit('verificationExpired');
  }

  manualReviewRequired = () => {
    this.view.$emit('manualReviewRequired');
  }

  initialize = async () => {
    this.loading = true;
    await this.loadMatiVerificationStatus();
    this.loading = false;
  }
}
